/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import {
  Typography,
  VariableContent,
  Link,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../components/Footer'
import header from '../components/Header'

import CountdownTimer from '../components/Countdown'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()

  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-plan',
    'disclaimer-evidence',
    'dsiclaimer-changenotice',
    'disclaimer-aetnabrand',
    'disclaimer-clicopyright',
    'disclaimer-incompletedescrip',
    'disclaimer-cmsmaterialid',
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title: 'Thank You | Aetna Medicare Direct',
          description: 'Thank You | Aetna Medicare Direct',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/thank-you',
        promoCode: '88487',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Thank You | Aetna Medicare Direct',
    defaultRobots: 'follow,index',

    main: (
      <>
        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h1">Thank You!</Typography>
              <Typography variant="body">
                Your information has been sent. A representative will call you
                shortly. If you have any questions please call{' '}
                <Link to={`tel:${rotatedNumber}`}>{rotatedNumber}</Link>.
              </Typography>
            </>
          }
        ></VariableContent>

        <CountdownTimer />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
